import React from 'react'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import imgBluebell from '../images/blue-bell-chips.jpg'
import imgJeff from '../images/penso-co-team-jeff.jpg'
import imgJared from '../images/penso-co-team-jared.jpg'
import imgMatt from '../images/penso-co-team-matt.jpg'

class Studio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      weather: {},
    }
  }

  componentDidMount() {
    fetch(
      '//api.openweathermap.org/data/2.5/weather?zip=97211,us&units=imperial&appID=253c62014ef5116c77bfd2eb999f5234'
    )
      .then(response => response.json())
      .then(
        response => {
          this.setState({
            isLoaded: true,
            weather: response,
          })
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  render() {
    const { error, isLoaded, weather } = this.state
    const { data } = this.props
    const content = data.prismicStudio.data
    return (
      <Layout>
        <Meta title="Studio" />
        <header id="page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Studio</h1>
              </div>
            </div>
          </div>
        </header>

        <section className="container lead-in">
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2">
              <Img
                fluid={content.featured_image.localFile.childImageSharp.fluid}
                alt={content.featured_image.alt}
              />
            </div>
            <div className="col-md-6 order-md-1">
              <h2>{content.intro}</h2>
            </div>
          </div>
        </section>

        <section className="container lead-in">
          <div className="row justify-content-between">
            <div
              className="col-md-7 philosophy"
              dangerouslySetInnerHTML={{ __html: content.body_copy.html }}
            ></div>
            <div className="col-md-4 fun-facts">
              <div className="stat">
                {!error && isLoaded && (
                  <>
                    <h2>
                      {Math.floor(weather.main.temp)}
                      <em>&deg;F</em>
                    </h2>
                    <p>{weather.weather[0].main}</p>
                  </>
                )}
              </div>
              {content.stats.map(stat => (
                <div className="stat" key={stat.id}>
                  <h2 dangerouslySetInnerHTML={{ __html: stat.primary.metric }}></h2>
                  <p>{stat.primary.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="container process">
          <div className="row">
            <div className="col">
              <h4>{content.team[0].primary.team_section}</h4>
            </div>
          </div>
          <div className="row">
            {content.team[0].items.map((member) => (
              <div className="col-md-4" key={member.id}>
                <Img
                  fluid={member.portrait.localFile.childImageSharp.fluid}
                  alt={member.portrait.alt}
                />
                <h3>{member.first_and_last_name}</h3>
                <p>{member.position}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="container">
          <div className="row py-md-5">
            <div className="col">
              <AniLink
                to="/what-we-do"
                className="view--all"
                cover
                duration={1}
                bg="#b88343"
              >
                Learn about What We Do
                <span className="icon-right-open" />
              </AniLink>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Studio

export const query = graphql`
  {
    prismicStudio {
      data {
        intro
        featured_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 570, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        body_copy {
          html
        }
        stats: body {
          id
          primary {
            label
            metric
          }
        }
        team: body1 {
          id
          items {
            first_and_last_name
            portrait {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 370, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            position
          }
          primary {
            team_section
          }
        }
      }
    }
  }
`
